import Calendar from '@toast-ui/calendar';
import Choices from "choices.js";
import u from 'umbrellajs';
import {
    addSchedulableTypeSelect,
    addSchedulableSelect,
    formatDate,
    processErrorMessages,
    populateForm,
    clearForm,
    clearErrors,
    loadCalendarSchedules,
    appendSchedulesToCalendar,
    updateRepeatSchedulesRules,
    removeFromSchedulesRules, putsRepeatsOnCalendar
} from './helpers/scheduler.js'


$(document).ready(async () => {
    if (u('body').is('.schedulers.show')) {
        const calendar = new Calendar('#calendar', {
            gridSelection: true,disableDblClick: true, usageStatistics: false, defaultView: 'week', week: {
                taskView: false, eventView: ['time']
            }, calendars: [{
                id: 'cal_1', backgroundColor: '#ff407b',
            }],
        });


        let repeatSchedulesRules = []
        const {schedules, scheduleRules} = await loadCalendarSchedules(calendar.getDateRangeStart().d.d, calendar.getDateRangeEnd().d.d, repeatSchedulesRules);
        repeatSchedulesRules = scheduleRules
        appendSchedulesToCalendar(calendar, schedules)
        putsRepeatsOnCalendar(calendar, repeatSchedulesRules)

        const selectFieldSchedulableType = u('#select_type')
        const selectFieldSchedulable = u('#select_group_or_user')

        const schedulableSelect = await addSchedulableSelect(selectFieldSchedulable)
        const schedulableTypeSelect = addSchedulableTypeSelect(selectFieldSchedulableType, schedulableSelect)

        $('#modal-schedule').on('show.bs.modal', (e) => {
            const startTime = $('#modal-schedule').data('start-time')
            const endTime = $('#modal-schedule').data('end-time')
            const startField = u('#modal-schedule').find('#schedule_start').first()
            const endField = u('#modal-schedule').find('#schedule_end').first()
            const eventId = $('#modal-schedule').data('event-id')
            startField.value = formatDate(startTime)
            endField.value = formatDate(endTime)
            if (eventId) {
                u('#delete-event').removeClass('d-none');
            }

        })

        $('#modal-schedule').on('hidden.bs.modal', (e) => {
            $('#modal-schedule').data('start-time', null)
            $('#modal-schedule').data('end-time', null)
            $('#modal-schedule').data('event-id', null)
            const formElement = u('#new-schedule').first()
            clearForm({
                formHTML: formElement, otherFields: {
                    "schedule[schedulable_type]": schedulableTypeSelect,
                    "schedulable[schedulable_id]": schedulableSelect
                }
            })
            clearErrors(formElement)
            u('#delete-event').addClass('d-none');
        })

        u('#delete-event').on('click', async (e) => {
            const form = u('#new-schedule')
            const autenticityTokenField = form.find('input[name=authenticity_token]').first()
            const autenticityToken = autenticityTokenField.value
            const eventId = $('#modal-schedule').data('event-id')
            try {
                const response = await fetch(`/schedules/${eventId}`, {
                    method: 'DELETE'
                })
                const respJson = await response.json()
                if (respJson.result) {
                    calendar.deleteEvent(eventId, 'cal_1')
                    removeFromSchedulesRules(repeatSchedulesRules, eventId)
                    putsRepeatsOnCalendar(calendar, repeatSchedulesRules)
                }
                $('#modal-schedule').modal('hide')

            } catch (e) {
                console.error("Error deleting schedule", e)
            }

        })

        u('#save-event').on('click', async (e) => {
            e.preventDefault()
            const formElement = $('#new-schedule').get(0)
            const prevSchedule = $('#modal-schedule').data('schedule')
            const form = new FormData(formElement, $('#save-event').get(0))
            try {
                const response = await fetch(formElement.action, {
                    method: formElement.dataset.method, body: form
                })
                const respJson = await response.json()
                if (!respJson.result) {
                    processErrorMessages(formElement, respJson.errors)
                } else {
                    if (formElement.dataset.method === 'post') {
                        calendar.createEvents([{
                            calendarId: "cal_1",
                            id: respJson.schedule.id,
                            title: respJson.schedule.name,
                            start: respJson.schedule.start,
                            end: respJson.schedule.end,
                        }])
                        calendar.clearGridSelections()
                    } else if (formElement.dataset.method === 'put') {
                        calendar.updateEvent(respJson.schedule.id, 'cal_1', {
                            title: respJson.schedule.name, start: respJson.schedule.start, end: respJson.schedule.end,
                        });

                    }
                    updateRepeatSchedulesRules(repeatSchedulesRules, respJson.schedule)
                    putsRepeatsOnCalendar(calendar, repeatSchedulesRules)
                    if (prevSchedule && prevSchedule.repeat === true && respJson.schedule.repeat === false) {
                        calendar.createEvents([{
                            calendarId: "cal_1",
                            id: respJson.schedule.id,
                            title: respJson.schedule.name,
                            start: respJson.schedule.start,
                            end: respJson.schedule.end,
                        }])
                    }
                    $('#modal-schedule').modal('hide')
                }
            } catch (e) {
                console.error("Error fetching data", e)
            }
        })

        u('#cancel-event').on('click', (e) => {
            const formElement = u('#new-schedule').first()
            $('#modal-schedule').modal('hide')
            calendar.clearGridSelections();
        })

        u('#button-prev').on('click', async () => {
            calendar.prev()
            const from = calendar.getDateRangeStart().d.d
            const to = calendar.getDateRangeEnd().d.d
            const {schedules, scheduleRules} = await loadCalendarSchedules(from, to, repeatSchedulesRules)
            repeatSchedulesRules = scheduleRules
            appendSchedulesToCalendar(calendar, schedules)
            putsRepeatsOnCalendar(calendar, repeatSchedulesRules)
        })

        u('#button-next').on('click', async () => {
            calendar.next()
            const from = calendar.getDateRangeStart().d.d
            const to = calendar.getDateRangeEnd().d.d
            const {schedules, scheduleRules} = await loadCalendarSchedules(from, to, repeatSchedulesRules)
            repeatSchedulesRules = scheduleRules
            appendSchedulesToCalendar(calendar, schedules)
            putsRepeatsOnCalendar(calendar, repeatSchedulesRules)
        })

        calendar.on('selectDateTime', (dti) => {
            const form = u('#new-schedule')
            u(form).attr('action', '/schedules')
            u(form).data('method', 'post')
            $('#modal-schedule').data('start-time', dti.start)
            $('#modal-schedule').data('end-time', dti.end)
            $('#modal-schedule').modal({backdrop: 'static'})

        })

        calendar.on('clickEvent', async (e) => {
            const event = e.event
            const form = u('#new-schedule')
            form.attr('action', `/schedules/${event.id}`)
            form.data('method', 'put')
            $('#modal-schedule').data('event-id', event.id)
            $('#modal-schedule').data('start-time', event.start)
            $('#modal-schedule').data('end-time', event.end)
            schedulableSelect.disable()
            try {
                const response = await fetch(`/schedules/${event.id}`);
                const respJson = await response.json()
                populateForm({
                    formHTML: form.first(), otherFields: {
                        "schedule[schedulable_type]": schedulableTypeSelect,
                        "schedulable[schedulable_id]": schedulableSelect
                    }
                }, respJson)
                $('#modal-schedule').data('schedule', respJson)
            } catch (e) {
                console.error(`Error fetching schedule with id ${event.id}`, e)
            }
            $('#modal-schedule').modal({backdrop: 'static'})

        })

        calendar.on('beforeUpdateEvent', async (e) => {
            const originalEvent = e.event
            let newStart = originalEvent.start.toDate()
            let newEnd = originalEvent.end.toDate()
            if (e.changes.start) {
                newStart = e.changes.start.toDate()
            }
            if(e.changes.end) {
                newEnd = e.changes.end.toDate()
            }

            try {
                const data = new URLSearchParams();
                data.append('schedule[start]', newStart);
                data.append('schedule[end]', newEnd);
                const response = await fetch(`/schedules/${originalEvent.id}`, {
                    method: 'PUT', body: data
                })
                const respJson = await response.json()
                updateRepeatSchedulesRules(repeatSchedulesRules, respJson.schedule)
                putsRepeatsOnCalendar(calendar, repeatSchedulesRules)
                calendar.updateEvent(originalEvent.id, 'cal_1', {
                    title: respJson.schedule.name,
                    start: newStart,
                    end: newEnd
                })

            } catch(e) {
                console.error("Error updating schedule", e)
            }
        })

    }

})
