$(document).ready(function() {
    
    obj = document.getElementById("randompass");
    if (obj) {
        obj.addEventListener("click", myFunction);
    };
    
    function myFunction() {
        var randomstring = Math.random().toString(36).slice(-8);
        document.getElementById("hotspot_user_password").value = randomstring;
        alert("La password generata è "+randomstring);
    }
});