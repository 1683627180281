import Choices from "choices.js";
import u from 'umbrellajs';
import {datetime, RRule} from 'rrule'
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

export const addSchedulableTypeSelect = (element, linkedChoices) => {
    const selectField = u(element)

    if (selectField.length > 0) {
        const selectSchedulableType = new Choices(selectField.first(), {
            addItems: true, noResultsText: "Nessun risultato", allowHTML: true

        })


        selectField.on('change', async (event) => {
            const slectElement = event.target
            linkedChoices.removeActiveItems()
            if (!event.detail.value) {
                linkedChoices.disable()
                return;
            }
            let url = '';
            let valueMethod = '';
            let labelMethod = '';
            switch (event.detail.value) {
                case 'OauthAuthorizedUser':
                    url = "/oauth_authorized_users.json"
                    valueMethod = 'id';
                    labelMethod = 'email'
                    break;
                case 'HotspotUser':
                    url = "/hotspot_users.json"
                    valueMethod = 'id';
                    labelMethod = 'email'
                    break;
                case 'UserGroup':
                    url = "/groups.json"
                    valueMethod = 'id';
                    labelMethod = 'name'
                    break;
            }

            const options = await loadFetchDataPerPage(url, 1)
            const linkedChoicesValue = linkedChoices.passedElement.element.dataset.selectedValue
            linkedChoices.setChoices(options, valueMethod, labelMethod, true)
            if (linkedChoicesValue) {
                linkedChoices.setChoiceByValue(linkedChoicesValue)
            }
            linkedChoices.enable()
        }, false)

        return selectSchedulableType;

    }

}

const loadFetchDataPerPage = async (url, initialPage) => {
    let page = initialPage;
    let continueToLoad = true
    let items = []
    while (continueToLoad) {
        let response = {}
        try {
            const response = await fetch(`${url}?page=${page}&per_page=1`);
            const json = await response.json()
            const totalPage = json.total_page
            const total = json.total
            items = items.concat(json.items)
            if (total === 0) {
                continueToLoad = false;
            }
            if (totalPage == page) {
                continueToLoad = false;
            }
            if (page < totalPage) {
                page = json.page + 1
            }
        } catch (e) {
            console.error("error fetching the data", e)
            continueToLoad = false;
        }


    }
    return items;
}
export const addSchedulableSelect = async (element) => {
    const selectField = u(element)
    const options = []

    // (await loadFetchDataPerPage('groups.json', 1)).map((o) => ({label: o.name, value: o.id}))


    if (selectField.length > 0) {
        const selectSchedulableType = new Choices(selectField.first(), {
            addItems: true, noResultsText: "Nessun risultato", choices: options, allowHTML: true

        })
        selectSchedulableType.disable();

        return selectSchedulableType


    }

}

export const formatDate = (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');

    return `${year}-${month}-${day}T${hours}:${minutes}`;
}

export const processErrorMessages = (formElement, errors) => {
    const form = u(formElement);
    for (const [key, errMessages] of Object.entries(errors)) {
        const field = form.find(`[name*=${key}]`)
        const fieldParent = field.closest('.form-group')
        const selectDivField = field.closest('.choices')
        field.addClass('is-invalid')
        if (selectDivField.length !== 0) {
            selectDivField.addClass('is-invalid')
        }
        let messagesString = ""
        for (const mex of errMessages) {
            messagesString += `${mex}<br>`
        }

        const invalidFeedback = u('<div class="invalid-feedback">' + messagesString + '</div>')
        fieldParent.append(invalidFeedback)
    }

}

export const populateForm = (formAndFields, schedule) => {
    const form = u(formAndFields.formHTML)
    const formData = new FormData(formAndFields.formHTML)
    for (const [prop, value] of Array.from(formData.entries()).concat(Object.entries(formAndFields.otherFields))) {
        if (!prop.match(/\[.*\]/)) {
            continue
        }
        let field;
        if (typeof value === 'object') {
            field = value
        } else {
            field = form.find(`[name="${prop}"]:not([type="hidden"])`)
        }
        const propName = prop.match(/\[(?<propName>.*)\]/)[1]

        if (field.constructor.name === "Choices") {
            field.setChoiceByValue(schedule[propName])
            field.passedElement.element.dataset.selectedValue = schedule[propName]
            field.passedElement.element.dispatchEvent(new CustomEvent('change', {detail: {value: schedule[propName]}}))

        } else if (field.first().type === "checkbox") {
            field.first().checked = schedule[propName]
        } else {
            field.first().value = schedule[propName]
        }
    }
}

export const clearErrors = (formElement) => {
    const form = u(formElement);
    const fields = form.find('.is-invalid')
    fields.each((field) => {
        u(field).removeClass('is-invalid')
        const fieldParent = u(field).closest('.form-group')
        const selectDivField = u(field).closest('.choices')
        fieldParent.find('.invalid-feedback').remove()
        if (selectDivField.length !== 0) {
            selectDivField.removeClass('is-invalid')
        }
    })
}

export const clearForm = (formAndFields) => {
    const form = u(formAndFields.formHTML)
    const fields = form.find('input:not([type="hidden"]), select:not([type="hidden"]), textarea:not([type="hidden"])')
    for (const element of fields.nodes.concat(Object.values(formAndFields.otherFields))) {
        if (element.constructor.name === "Choices") {
            element.removeActiveItems()
        } else if (element.type === "checkbox") {
            element.checked = false
        } else {
            element.value = ""
        }

    }
}

export const updateRepeatSchedulesRules = (scheduleRules, schedule) => {
    if (!schedule.repeat) {
        return;
    }
    const already = scheduleRules.find((sr) => sr.schedule.id === schedule.id);
    if (!already) {
        scheduleRules.push({rule: new RRule({
                freq: RRule.DAILY,
                interval: schedule.every,
                byweekday: [RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR, RRule.SA, RRule.SU],
                dtstart: datetime(new Date(schedule.start).getUTCFullYear(),
                    new Date(schedule.start).getUTCMonth() + 1,
                    new Date(schedule.start).getUTCDate(),
                    new Date(schedule.start).getUTCHours(),
                    new Date(schedule.start).getUTCMinutes()),

            }),
            schedule: {...schedule, start: new Date(schedule.start), end: new Date(schedule.end)}})
    } else {
        if (!schedule.repeat) {
            removeFromSchedulesRules(scheduleRules, schedule.id)
        } else {
            already.schedule = {...schedule, start: new Date(schedule.start), end: new Date(schedule.end)};
            already.rule = new RRule({
                freq: RRule.DAILY,
                interval: schedule.every,
                byweekday: [RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR, RRule.SA, RRule.SU],
                dtstart: datetime(new Date(schedule.start).getUTCFullYear(),
                    new Date(schedule.start).getUTCMonth() + 1,
                    new Date(schedule.start).getUTCDate(),
                    new Date(schedule.start).getUTCHours(),
                    new Date(schedule.start).getUTCMinutes()),

            })
        }
    }
}

export const removeFromSchedulesRules = (scheduleRules, scheduleId) => {
    const found = scheduleRules.find((sr) => sr.schedule.id === scheduleId);
    if(found) {
        found.deleted = true;
    }
};
export const loadCalendarSchedules = async (from, to, scheduleRules = []) => {
    const fromTimeZoned = from.toLocaleString("it-IT", {timeZone: "Europe/Rome"});
    const toTimeZoned = to.toLocaleString("it-IT", {timeZone: "Europe/Rome"});
    const response = await fetch(`/schedules?q[start_gteq]=${fromTimeZoned}&q[end_lteq]=${toTimeZoned}`)
    const respJson = await response.json()
    const schedules = respJson.filter((s) => !s.repeat).map(s => ({...s, start: new Date(s.start), end: new Date(s.end)}));
    respJson.filter((s) => s.repeat).forEach((s) => {
        s = {...s, start: new Date(s.start), end: new Date(s.end)}
        updateRepeatSchedulesRules(scheduleRules, s)
    })
    return {schedules, scheduleRules: scheduleRules};
}

export const putsRepeatsOnCalendar = (calendar, scheduleRules, deletedId = null) => {

    const calendarStart = calendar.getDateRangeStart().d.d
    const calendarEnd = calendar.getDateRangeEnd().d.d
    calendarEnd.setHours(23, 59, 59)
    const schedulesIds = scheduleRules.map((sr) => sr.schedule.id)
    calendar.store.getState().calendar.events.each((e) => {
        if (schedulesIds.includes(e.id)) {
            calendar.deleteEvent(e.id,"cal_1")
        }
    })
    const schedules = scheduleRules.filter(sr => sr.deleted !== true).map((sr) => {
        const dates = sr.rule.between(datetime(
            new Date(calendarStart).getFullYear(),
            new Date(calendarStart).getMonth() + 1,
            new Date(calendarStart).getDate(),
            new Date(calendarStart).getHours(),
            new Date(calendarStart).getMinutes()),
            datetime(
                new Date(calendarEnd).getFullYear(),
                new Date(calendarEnd).getMonth() + 1,
                new Date(calendarEnd).getDate(),
                new Date(calendarEnd).getHours(),
                new Date(calendarEnd).getMinutes()));
        return dates.map((d) =>  {
            const {start, end} = datesWithOffset(sr.schedule.start,sr.schedule.end,d)
            return{
            calendarId: "cal_1",
            id: sr.schedule.id,
            title: sr.schedule.name,
            start: start,
            end: end
        }})
    }).flat()
    if(schedules.length > 0) {
        calendar.createEvents(schedules)
    }
}

export const appendSchedulesToCalendar = (calendar, schedules) => {
    calendar.clear();
    const schedulesNoRepeat = schedules.map((s) => ({
        calendarId: "cal_1",
        id: s.id,
        title: s.name,
        start: s.start,
        end: s.end,
    }))
    calendar.createEvents(schedulesNoRepeat)
}

const datesWithOffset = (start,end, d) => {
    const offset = start.getTimezoneOffset();
    const newOffset = d.getTimezoneOffset();
    const diffOffset = newOffset - offset;
    const newStartDate = new Date(d.getTime() + (diffOffset * 60000))
    const duration = Math.abs(end.getTime()  - start.getTime())
    const newEndDate = new Date(newStartDate.getTime() + duration)
    return {start: newStartDate, end: newEndDate}
}
