require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")

import $ from "jquery"
import toastr from "toastr"
window.$ = $
window.jQuery = $
window.toastr = toastr

require("bootstrap")
require("@fortawesome/fontawesome-free/js/all")

